import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import './style.css'
import avatar from './img/avatar.svg'

import LetterList from '../letters/LetterList';

const Profile = (props) => {

  const { auth, profile, letters } = props;

  var wordCount = 0;

  if (!auth.uid) return <Redirect to="/signin" />


  const getWordCount = () => {
    letters && letters.map((letter) => {
      return wordCount += letter.body.length ? letter.body.trim().split(/\s+/).length : 0
    })
    return wordCount;
  }

  return (
    <div className="user-profile uk-margin-large-top">
      <div className="uk-container">

        <div className="user-profile-wrapper">
          <div className="user-avatar uk-margin-medium-right"><img src={profile.userAvatar ? profile.userAvatar : avatar} alt="User Avatar" /></div>
          <div className="user-meta">
            <div className="user-name uk-margin-small-bottom">{profile.displayName}</div>
            <div className="user-stats">
              <div className="user-drafts uk-margin-medium-right">
                <div className="draft-no">{(letters?letters.length:0)}</div>
                <div className="draft-text">Letters</div>
              </div>
              <div className="user-words">
                <div className="word-no">{getWordCount()}</div>
                <div className="word-text">Words</div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-margin-large-top">
          <h2 className="uk-margin-medium-bottom">Letters//</h2>

          <ul uk-tab="">
            <li className="uk-active"><a href="">Draft</a></li>
            <li><a href="">Sent</a></li>
          </ul>

          <ul className="uk-switcher uk-margin-medium-top">
            <li><LetterList draft={true} letters={letters && letters.filter(data => { return data.sent === false })} /></li>
            <li><LetterList draft={false} letters={letters && letters.filter(data => { return data.sent === true })} /></li>
          </ul>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("State", state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    letters: state.firestore.ordered.letters,
  }

}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    {
      collection: 'letters',
      orderBy: [['createdAt', 'desc',]],
      where: ['authorId', '==', props.auth.uid?props.auth.uid:'qwerty']
    },
  ])
)(Profile);
