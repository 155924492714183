import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';

import avatar from './img/avatar.svg';

const SignedInLinks = (props) => {

  const { userAvatar } = props;

  const handleSignOut = (e) => {
    e.preventDefault();
    props.signOut();
  }

  return (
    <div className="uk-navbar-right">
      <ul className="uk-navbar-nav">
        {/* <li>
          <NavLink to='/gettingStarted'>
            <span>Getting Started</span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to='/browse'>
            <span>Browse</span>
          </NavLink>
        </li> */}
        <li>
          <a href="#"><img
            className="uk-border-circle signed-avatar"
            src={userAvatar ? userAvatar : avatar}
            alt="Border circle" /></a>
          <div uk-dropdown="mode: click" className="uk-navbar-dropdown">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li>
                <NavLink to="/profile">Profile</NavLink>
              </li>
              <li>
                <NavLink to="/logout" onClick={handleSignOut}>Logout</NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="">
          <NavLink to='/new'>
            <span className="btn-deco"><span className="uk-hidden@s">Write</span><span className="uk-visible@s">Start Writing</span></span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);