import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
// Initialize Firebase
var config = {
  apiKey: "AIzaSyC_Gr5cSD-X3SFkH9SfPjjHaNNDBKfDzug",
  authDomain: "lettertoxyz.firebaseapp.com",
  databaseURL: "https://lettertoxyz.firebaseio.com",
  projectId: "lettertoxyz",
  storageBucket: "lettertoxyz.appspot.com",
  messagingSenderId: "1096394594992"
};

if (process.env.NODE_ENV === 'development') {
  config = {
    apiKey: "AIzaSyBk98biR1h2yIaq3PGYLMRWBsqxuK8d-1g",
    authDomain: "lettertoxyz-stagging.firebaseapp.com",
    databaseURL: "https://lettertoxyz-stagging.firebaseio.com",
    projectId: "lettertoxyz-stagging",
    storageBucket: "lettertoxyz-stagging.appspot.com",
    messagingSenderId: "573112970315",
    appId: "1:573112970315:web:d6355d6102c184370031ce"
  }
}

firebase.initializeApp(config);
firebase
  .firestore()

export default firebase;