import React, { useState } from 'react';
import GoogleButton from 'react-google-button';
import { connect } from 'react-redux';
import { signIn, signInWithGoogle } from '../../store/actions/authActions';
import { Redirect, Link } from 'react-router-dom';

import './style.css'
import signupIllustration from './img/signup-illustration.svg';

const SignIn = (props) => {

  const [email,
    setEmail] = useState("");
  const [password,
    setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    props.signIn({ email, password });
  }

  function handleGoogleSignIn() {
    props.signInWithGoogle();
  }

  const { auth } = props;

  if (auth.uid) return <Redirect to="/profile" />

  return (
    <section className="signIn">
      <div className="uk-padding-large"></div>
      <div className="uk-padding uk-hidden@l"></div>
      <div className="uk-container">

        <div className="div signin-container uk-flex uk-flex-column-reverse uk-flex-row@m">
          <div className="signup-visual uk-width-1-2@m uk-margin-top uk-margin-remove-top@m uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <div className="signup-text text-title text-color-primary uk-text-center">“To write is human, to receive a letter: Devine!” ― Susan Lendroth</div>
            <img src={signupIllustration} className="signup-illustration uk-margin-medium-top uk-visible@m" alt="signup-illustration" />
          </div>
          <div className="uk-width-1-2@m signin-form-container uk-padding">
            <div className="signin-form">
              <form onSubmit={handleSubmit}>
                <fieldset className="uk-fieldset">
                  <legend className="uk-legend signin-title text-title text-color-primary uk-margin-large-bottom">Welcome Back!</legend>
                  <div className="uk-margin">
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      className="uk-input form-line-style"
                      type="email"
                      placeholder="Email" />
                  </div>

                  <div className="uk-margin">
                    <input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      className="uk-input form-line-style"
                      type="password"
                      placeholder="Password" />
                  </div>

                  <div className="uk-margin submit-btn-container">
                    <input
                      className="uk-button btn-deco btn-small"
                      type="submit"
                      value="Signin"
                      placeholder="Login" />
                  </div>
                  <div>
                    {props.authError
                      ? <div className="uk-text-danger">{props.authError}</div>
                      : null}
                  </div>
                  <div>New User? <Link className="text-color-primary" to="/signup">Signup</Link></div>
                </fieldset>
              </form>
              <div className="or-container uk-margin">
                OR
              </div>
              <GoogleButton label="Continue with Google" type="light" onClick={handleGoogleSignIn} />
            </div>
          </div>
        </div>
      </div>
      <div className="uk-padding-small"></div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (cred) => {
      dispatch(signIn(cred));
    },
    signInWithGoogle: () => {
      dispatch(signInWithGoogle())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);