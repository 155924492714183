import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import editIcon from './img/edit.svg'

const LetterCard = (props) => {

  const { draft, letter } = props;
  let cta = { text: 'SEND', link: `/checkout/${letter.id}` }


  if (!draft)
    cta = { text: 'TRACK', link: `/track/${letter.DELIVERY ? letter.DELIVERY.DELIVERY_ID : ''}` }


  return (
    <div className="uk-width-1-2@m uk-margin-medium-bottom">
      <div className="letter-card-container uk-position-relative">
        <h3 className="to-XYZ uk-margin-large-bottom"><Link className="text-color-primary uk-link-reset" to={`/letter/${letter.id}`}>{letter.to}</Link></h3>
        <p className="letter-body uk-margin-medium-bottom">
          {(letter.body.length < 50 ? letter.body : (<span>{letter.body.split(' ').slice(0, 38).join(' ') + '...'}<Link className="uk-text-bold text-color-primary" to={`/letter/${letter.id}`}>more</Link></span>))}
        </p>
        <div className="letter-bottombar uk-flex-middle">
          <div className="letter-meta">
            <div className="author-name uk-margin-small-right">by {letter.displayName}</div>
          </div>
          <div className="read-more">
            {letter.DELIVERY || draft ? (<Link to={cta.link} className="btn-deco">{cta.text}</Link>) : ''}
          </div>
        </div>
        {draft && (<div className="edit-icon">
          <Link to={`/edit/${letter.id}`}><img src={editIcon} alt="" /></Link>
        </div>)}
      </div>
    </div>
  );
};

export default LetterCard;
