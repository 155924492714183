import React from 'react';
import LetterList from './LetterList'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const BrowseLetter = (props) => {
  // const { letters } = props;
  return (
    <div className="browse uk-container uk-margin-large-top">
      <LetterList letters={props.letters} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    letters: state.firestore.ordered.letters
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'letters',
      orderBy: [['createdAt', 'desc',]],
      where: ['public', '==', true]
    },
  ])
)(BrowseLetter);