import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createLetter, updateLetter } from '../../store/actions/letterActions';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import TextareaAutosize from 'react-autosize-textarea';
import './style.css';
import UIkit from 'uikit/dist/js/uikit';

const CreateLetter = (props) => {

  const { auth, activeLetterId, createLetter, updateLetter, history } = props;

  const [toXYX,
    setToXYX] = useState(props.to);
  const [letterBody,
    setLetterBody] = useState(props.content);
  const [created,
    setCreated] = useState(props.created);

  const notifyConfig ={status: 'success', pos: 'top-center', timeout: 200}


  function handleSave() {

    if (!toXYX.length && !letterBody.length) {
      UIkit.notification('Nothing to save!', notifyConfig);
    }
    else if (!created) {
      createLetter({ to: toXYX, body: letterBody, 'public': false })
      setCreated(true);
      UIkit.notification('New Letter is Saved!', notifyConfig);
    }
    else {
      updateLetter(activeLetterId, { to: toXYX, body: letterBody, 'public': false });
      UIkit.notification('Letter has been updated!', notifyConfig);
    }
  }

  function handleSend() {
    if (!toXYX.length && !letterBody.length) {
      UIkit.notification('Can\'t send blank letter!', notifyConfig);
    }
    else if (!created) {
      createLetter({ to: toXYX, body: letterBody, 'public': false })
      .then((letterId)=>{
        history.push(`/checkout/${letterId}`)
      });
      setCreated(true); 
    }
    else {
      console.log("From Else Send");
      updateLetter(activeLetterId, { to: toXYX, body: letterBody, 'public': false })
      .then(()=>{
        history.push(`/checkout/${activeLetterId}`)
      });
    }
  }

  if (!auth.uid)
    return <Redirect to="/signin" />

  return (
    <div className="createLetter uk-margin-large-top">
      <div className="create-letter-wrapper">
        <div className="uk-container uk-container-small">
          <div className="uk-margin save-btn-wrapper">
            <button
              onClick={() => { handleSave();props.history.goBack()}}
              className="uk-button uk-button-text uk-button-link"
              button>Close</button>
          </div>
          <form>
            <fieldset className="uk-fieldset">
              <div className="uk-margin">
                <input
                  value={toXYX}
                  onChange={(e) => {
                    let toText = e.target.value;
                    setToXYX(toText);
                  }}
                  className="uk-input to-input text-title"
                  type="text"
                  placeholder="to Someone who matters"
                  required />
              </div>

              <div className="uk-margin">
                <TextareaAutosize
                  value={letterBody}
                  onChange={(e) => {
                    let body = e.target.value;
                    setLetterBody(body);
                  }}
                  className="uk-textarea letter-body"
                  rows="10"
                  required
                  placeholder="Start Writing Something"></TextareaAutosize>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div className="create-bottom-bar bg-color-white">
        <div className="uk-margin-remove-top uk-container uk-container-small uk-flex uk-flex-between uk-flex-middle uk-padding-remove-bottom">
          <div className="word-count text-color-primary">{letterBody.length ? letterBody.trim().split(/\s+/).length : 0} Words so far</div>
          <div className="buttons uk-margin-small-bottom">
            <button onClick={handleSave} className="btn-deco btn-invert uk-margin-small-top">Save</button>
            <button onClick={handleSend} className="btn-deco uk-margin-small-top">Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.firebase.auth, activeLetterId: state.letter.activeLetterId }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createLetter: (letter) => {
      return dispatch(createLetter(letter))
    },
    updateLetter: (letterId, letter) => {
      return dispatch(updateLetter(letterId, letter))
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
  )(CreateLetter);