import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

import privateIcon from './img/private.svg';
import envelopeIcon from './img/envelope.svg';
import letterIcon from './img/letter.svg';
import { getPrice } from '../../util/pricing';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

// import CountryDropDown from './CountryDropDown';

var stripeKey = 'pk_live_7oKUZAY6kvCLxd7nsaikZiHO00vYXN4C5W'

if (process.env.NODE_ENV === 'development')
  stripeKey = 'pk_test_c7OhsPPpQ3uPS5FwN8ydXmzr00n3VGvGMf';

var stripe = window.Stripe(stripeKey);

const Checkout = (props) => {

  const letterId = props.match.params.id;
  var apiLink = 'https://payment.letterflix.com';
  const { auth, detectedUser } = props;

  const uid = auth.uid;

  const price = getPrice(detectedUser ? detectedUser.currency : 'USD');

  const [address, setAddress] = useState({
    NAME: '',
    STREET: '',
    CITY: '',
    PINCODE: '',
    STATE: '',
    COUNTRY: detectedUser.country_name,
    PHONE: '',
    SENDER_NAME: '',
    FROM: {
      STREET: '',
      CITY: '',
      PINCODE: '',
      STATE: '',
      COUNTRY: detectedUser.country_name
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  if (process.env.NODE_ENV === 'development')
    apiLink = 'http://localhost:5000';

  if (!uid)
    return <Redirect to='/signin' />

  const handleSubmit = (e) => {
    e.preventDefault();
    getSesssionid({
      address,
      LETTER_ID: letterId,
      CUST_ID: uid,
      email: auth.email,
      currency: price.currency
    })
      .then(res => {
        console.log("From Handle Click", res);
        stripe.redirectToCheckout({
          sessionId: res.id
        })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            if (result.error)
              alert("Payment Failed!");
          });
      })
      .catch((err) => {
        console.log("Err", err);
      })
  }

  const getSesssionid = async (data) => {
    // Default options are marked with *
    const response = await fetch(`${apiLink}/stripe`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return await response.json(); // parses JSON response into native JavaScript objects
  }


  return (
    <section className="signIn">
      <div className="uk-padding-large"></div>
      <div className="uk-padding uk-hidden@l"></div>
      <div className="uk-container">

        <div className="div signin-container uk-flex uk-flex-column-reverse uk-flex-row@m">
          <div className="signup-visual uk-width-1-2@m uk-margin-top uk-margin-remove-top@m uk-flex uk-flex-column uk-flex-top@m uk-flex-middle">
            <div className="uk-padding uk-visible@m"></div>
            <div>
              <div className="signup-text text-title text-color-primary">Pinky Promises:</div>
              <div className="uk-flex uk-margin-top uk-flex-middle">
                <img src={privateIcon} alt="" />
                <h4 className="text-body uk-margin-remove-bottom uk-margin-remove-top uk-margin-small-left">Your letters always remain private.</h4>
              </div>
              <div className="uk-flex uk-flex-middle uk-margin-top uk-flex-middle">
                <img src={envelopeIcon} alt="" />
                <h4 className="text-body uk-margin-remove-bottom uk-margin-remove-top uk-margin-small-left">Your words get delivered in an envelope (yes, really!).</h4>
              </div>
              <div className="uk-flex uk-flex-middle uk-margin-top uk-flex-middle">
                <img src={letterIcon} alt="" />
                <h4 className="text-body uk-margin-remove-bottom uk-margin-remove-top uk-margin-small-left">Your recipient looks cute holding your letter.</h4>
              </div>
            </div>

          </div>
          <div className="uk-width-1-2@m signin-form-container uk-padding">
            <div className="signin-form">
              <form action={`${apiLink}/payment`} method="POST" onSubmit={handleSubmit}>
                <div className="uk-fieldset">
                  <div>
                  </div>
                  <div className="uk-margin">
                    <input className="uk-input form-line-style" type="hidden" name="SPEED" placeholder="Customer Id" value="1" required />
                  </div>
                  <div className="uk-margin">
                    <input className="uk-input form-line-style" type="hidden" name="CUST_ID" placeholder="Customer Id" value={uid} required />
                  </div>

                  <div className="uk-margin">
                    <input className="uk-input form-line-style" type="hidden" name="LETTER_ID" placeholder="Letter ID Id" value={letterId} required />
                  </div>

                  <div className="uk-margin">
                    <input className="uk-input form-line-style" type="hidden" name="PHONE" placeholder="Phone Number" required value={address.PHONE} onChange={e => setAddress({ ...address, PHONE: e.target.value })} />
                  </div>

                  <legend className="uk-legend signin-title text-title text-color-primary uk-margin-bottom uk-margin-top">To</legend>
                  <div className="uk-margin-top"><input className="uk-input form-line-style uk-margin-small-bottom" type="text" name="NAME" placeholder="Receiver's Name" required value={address.NAME} onChange={e => setAddress({ ...address, NAME: e.target.value })} /></div>
                  <div className="uk-margin-top"><input className="uk-input form-line-style uk-margin-small-bottom" name="STREET" placeholder="Address (House No, Building, Street, Area)" required value={address.STREET} onChange={e => setAddress({ ...address, STREET: e.target.value })} /></div>

                  <div className="" uk-grid="true">
                    <div className="uk-margin-top uk-width-1-2"><input className="uk-input form-line-style uk-margin-small-bottom" type="text" name="CITY" placeholder="City/District" required value={address.CITY} onChange={e => setAddress({ ...address, CITY: e.target.value })} /></div>
                    <div className="uk-margin-top uk-width-1-2"><RegionDropdown country={address.COUNTRY} blankOptionLabel="Select State" defaultOptionLabel="Select State" className="uk-select form-line-style uk-margin-small-bottom" type="text" name="STATE" placeholder="State" required value={address.STATE} onChange={state => setAddress({ ...address, STATE: state })} /></div>
                  </div>
                  <div className="uk-margin-remove-top" uk-grid="true">
                    <div className="uk-margin-top uk-width-1-2"><CountryDropdown className="uk-select form-line-style" name="COUNTRY" placeholder="Country" required value={address.COUNTRY} country={address.COUNTRY} onChange={country => setAddress({ ...address, COUNTRY: country })} /></div>
                    <div className="uk-margin-top uk-width-1-2"><input className="uk-input form-line-style uk-margin-small-bottom" type="text" name="PINCODE" placeholder="Pincode" required value={address.PINCODE} onChange={e => setAddress({ ...address, PINCODE: e.target.value })} /></div>
                  </div>

                  {/* From Address */}
                  <legend className="uk-legend signin-title text-title text-color-primary uk-margin-bottom uk-margin-medium-top">From</legend>
                  <div className="uk-margin"> <input className="uk-input form-line-style" type="text" name="SENDER_NAME" placeholder="Sender's Name" required value={address.SENDER_NAME} onChange={e => setAddress({ ...address, SENDER_NAME: e.target.value })} /></div>
                  <div className="uk-margin-top"><input className="uk-input form-line-style uk-margin-small-bottom" name="FROM_STREET" placeholder="Address (House No, Building, Street, Area)" required value={address.FROM.STREET} onChange={e => setAddress({ ...address, FROM: { ...address.FROM, STREET: e.target.value } })} /></div>

                  <div className="" uk-grid="true">
                    <div className="uk-margin-top uk-width-1-2"><input className="uk-input form-line-style uk-margin-small-bottom" type="text" name="FROM_CITY" placeholder="City/District" required value={address.FROM.CITY} onChange={e => setAddress({ ...address, FROM: { ...address.FROM, CITY: e.target.value } })} /></div>
                    <div className="uk-margin-top uk-width-1-2"><RegionDropdown country={address.FROM.COUNTRY} blankOptionLabel="Select State" defaultOptionLabel="Select State" className="uk-select form-line-style uk-margin-small-bottom" type="text" name="FROM_STATE" placeholder="State" required value={address.FROM.STATE} onChange={state => setAddress({ ...address, FROM: { ...address.FROM, STATE: state } })} /></div>
                  </div>
                  <div className="uk-margin-remove-top" uk-grid="true">
                    <div className="uk-margin-top uk-width-1-2"><CountryDropdown className="uk-select form-line-style" name="FROM_COUNTRY" placeholder="Country" required value={address.FROM.COUNTRY} country={address.COUNTRY} onChange={country => setAddress({ ...address, FROM: { ...address.FROM, COUNTRY: country } })} /></div>
                    <div className="uk-margin-top uk-width-1-2"><input className="uk-input form-line-style uk-margin-small-bottom" type="text" name="FROM_PINCODE" placeholder="Pincode" required value={address.FROM.PINCODE} onChange={e => setAddress({ ...address, FROM: { ...address.FROM, PINCODE: e.target.value } })} /></div>
                  </div>
                  <div className="uk-margin-medium-top submit-btn-container">
                    <input
                      className="uk-button btn-deco btn-small"
                      type="submit"
                      value="Pay & Send this letter"
                    />
                  </div>
                  <p style={{ color: '#e31e1e', fontSize: '15px' }}>Please note: Live Tracking is only Available for Letters sent to and within India.</p>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-padding-small"></div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {

  return { auth: state.firebase.auth, detectedUser: state.userDetection.detectedUser }
}

export default compose(connect(mapStateToProps), firestoreConnect(props => [
  {
    collection: 'letters', doc: props.match.params.id,
  },
]))(Checkout);