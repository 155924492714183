import React from 'react';
import { NavLink } from 'react-router-dom';

const SignedOutLinks = () => {
  return (
    <div className="uk-navbar-right">
      <ul className="uk-navbar-nav">
        {/* <li>
          <NavLink to='/gettingStarted'>
            <span>Getting Started</span>
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to='/browse'>
            <span>Browse</span>
          </NavLink>
        </li> */}
        <li>
          <NavLink to='/signup'>
            <span>Sign Up</span>
          </NavLink>
        </li>
        <li>
          <NavLink to='/signin'>
            <span>Sign In</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SignedOutLinks;