import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { Link } from 'react-router-dom'

import { updateViewCount } from '../../store/actions/letterActions';
import './style.css';

const LetterDetail = (props) => {
  const letterId = props.match.params.id;
  const { letter } = props;

  let cta = { text: 'SEND', link: `/checkout/${letterId}` }

  if (letter && letter.sent)
    cta = { text: 'TRACK', link: `/track/${letter.DELIVERY ? letter.DELIVERY.DELIVERY_ID : ''}` }

  useEffect(() => {
    window.scrollTo(0, 0);
    props.updateViewCount(letterId);
  }, [
    letterId, letter
      ? letter.id
      : 0
  ]);

  const getFromatedDate = (timestamp) => {
    let date  = new Date(timestamp.seconds * 1000);
    return date.toDateString()
  }

  return (
    <div className="letter-detail">
      {letter
        ? (
          <div className="letter-detail-wrapper">
            <div className="uk-container">
              <div className="uk-margin save-btn-wrapper">
                <button
                  onClick={() => { props.history.goBack() }}
                  className="uk-button uk-button-text uk-button-link"
                  button>Close
              </button>
              </div>

              {letter.DELIVERY ? (
                <div>
                  <h4 className="text-title text-color-primary uk-margin-small-bottom">To</h4>
                  <div className="uk-text-bold">{letter.DELIVERY.ADDRESS.NAME}</div>
                  <div>{letter.DELIVERY.ADDRESS.STREET}, {letter.DELIVERY.ADDRESS.PHONE && <span>Phone: {letter.DELIVERY.ADDRESS.PHONE}</span>}</div>
                  <div>{letter.DELIVERY.ADDRESS.CITY}, {letter.DELIVERY.ADDRESS.STATE}, {letter.DELIVERY.ADDRESS.PINCODE}</div>
                  <div>{letter.DELIVERY.ADDRESS.COUNTRY}</div>
                  <div className="uk-margin-small-top"><span className="uk-text-bold">Sent on: </span>{getFromatedDate(letter.DELIVERY.DATE)}</div>
                </div>
              ) : null}
              <h3 className="to-XYZ">{letter.to.trim()}</h3>
              <p className="letter-body">
                {letter.body.trim()}
              </p>
              <div className="letter-bottombar">
                <div className="letter-meta">
                  <div className="author-name uk-margin-small-right">by {letter.displayName}</div>
                </div>
              </div>
            </div>
            <div className="create-bottom-bar bg-color-white">
              <div className="uk-margin-remove-top uk-container uk-container-small uk-flex uk-flex-between uk-flex-middle uk-padding-remove-bottom uk-padding-remove-top">
                <div className="word-count text-color-primary">{letter.body.length ? letter.body.trim().split(/\s+/).length : 0} Words</div>
                <div className="buttons uk-margin-small-bottom">
                  {!letter.sent && <Link to={`/edit/${letterId}`} className="btn-deco btn-invert uk-margin-small-top">Edit</Link>}
                  {letter.DELIVERY || !letter.sent ? (<Link to={cta.link} className="btn-deco uk-margin-small-top uk-margin-left">{cta.text}</Link>) : ''}
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div>Loading</div>
        )
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const letters = state.firestore.data.letters;
  const letter = letters
    ? letters[id]
    : null;
  return { letter: letter, uid: state.firebase.auth.uid }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateViewCount: (letterId, letter) => {
      dispatch(updateViewCount(letterId));
    }
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps), firestoreConnect(props => [
  {
    collection: 'letters', doc: props.match.params.id,
  },
]))(LetterDetail);
