import React, { useState } from 'react';
import GoogleButton from 'react-google-button';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { signUp, signInWithGoogle } from '../../store/actions/authActions'

import './style.css'
import signupIllustration from './img/signup-illustration.svg';

const SignUp = (props) => {

  const { auth, signUp } = props;

  const [email,
    setEmail] = useState("");
  const [password,
    setPassword] = useState("");
  const [displayName,
    setDisplayName] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    signUp({ email, password, displayName })
  }

  function handleGoogleSignIn() {
    props.signInWithGoogle();
  }

  if (auth.uid)
    return <Redirect to="/profile" />

  return (
    <section className="signUp">
       <div className="uk-padding-large"></div>
      <div className="uk-padding uk-hidden@l"></div>
      <div className="uk-container">
        <div className="signup-container uk-flex uk-flex-column-reverse uk-flex-row@m">
          <div className="signup-visual uk-width-1-2@m uk-margin-top uk-margin-remove-top@m uk-flex uk-flex-column uk-flex-center uk-flex-middle">
            <div className="signup-text text-title text-color-primary uk-text-center">“To write is human, to receive a letter: Devine!” ― Susan Lendroth</div>
            <img src={signupIllustration} className="signup-illustration uk-margin-medium-top uk-visible@m" alt="signup-illustration" />
          </div>
          <div className="uk-width-1-2@m signup-form-container uk-padding">
            <div className="signup-form">
              <form onSubmit={handleSubmit}>
                <fieldset className="uk-fieldset">
                  <legend className="uk-legend signup-title text-title text-color-primary uk-margin-large-bottom">Get Started</legend>
                  <div className="uk-margin">
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                      className="uk-input form-line-style"
                      type="email"
                      placeholder="Email" />
                  </div>

                  <div className="uk-margin">
                    <input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                      className="uk-input form-line-style"
                      type="password"
                      placeholder="Password" />
                  </div>

                  <div className="uk-margin">
                    <input
                      value={displayName}
                      onChange={(e) => {
                        setDisplayName(e.target.value)
                      }}
                      className="uk-input form-line-style"
                      type="text"
                      placeholder="Display Name" />
                  </div>
                  <div className="uk-margin submit-btn-container">
                    <input
                      className="uk-button btn-deco btn-small"
                      type="submit"
                      value="Sign Up"
                      placeholder="Login" />
                  </div>
                  <div>Already Registered? <Link className="text-color-primary" to="/signin">Signin</Link></div>
                </fieldset>
              </form>
              <div className="or-container uk-margin">
                OR
              </div>
              <GoogleButton label="Continue with Google" type="light" onClick={handleGoogleSignIn} />
            </div>
          </div>
        </div>
      </div>
      <div className="uk-padding-small"></div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { auth: state.firebase.auth }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => {
      dispatch(signUp(newUser))
    },
    signInWithGoogle: () => {
      dispatch(signInWithGoogle())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);