import React from 'react';
import queryString from 'query-string';
import PostPayment from './PostPaymentCard';

const Success = (props) => {
  const params = queryString.parse(props.location.search);
  const session_id = params.session_id && params.session_id;

  return (
    <PostPayment success={true} session_id={session_id} />
  );
};

export default Success;