import React from 'react';

import LetterCard from './LetterCard'

const LetterList = (props) => {
  const { letters } = props;
  return (
    <div className="letterListWrapper">
      <div className="uk-grid" uk-grid="true" uk-height-match="target: > div > div">
        {letters === undefined ? <div className="uk-text-center">Loading</div> :
          letters.length ? letters.map((letter => {
            return <LetterCard draft={props.draft} key={letter.id} letter={letter} />;
          })) : <div className="uk-text-center">No letters yet</div>
        }
      </div>
    </div>
  );
};

export default LetterList;