export const createLetter = (letter) => {
  return (dispatch, getState, { getFirestore }) => {

    const fireStore = getFirestore();
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    return fireStore
      .collection('letters')
      .add({
        displayName: profile.displayName || '',
        views: 0,
        authorId:authorId,
        public: false,
        createdAt: new Date(),
        ...letter,
        sent:false
      })
      .then((res) => {
        dispatch({ type: "CREATE_LETTER", letter,'activeLetterId':res.id })
        return res.id;
      })
      .catch((err) => {
        dispatch({
          type: "CREATE_LETTER_ERROR"
        }, err)
      })
  }
};

export const updateLetter = (letterId, letter) => {
  return (dispatch, getState, { getFirestore }) => {

    const fireStore = getFirestore();
    return fireStore
      .collection('letters')
      .doc(letterId)
      .update({
        ...letter,
      })
      .then(() => {
        return dispatch({ type: "UPDATE_LETTER" })
      })
      .catch((err) => {
        return dispatch({
          type: "UPDATE_LETTER_ERROR"
        }, err)
      });
  };
}

export const updateViewCount = (letterId) => {
  return (dispatch, getState, { getFirestore }) => {

    if (
      !getState().firestore.data.letters ||
      !getState().firestore.data.letters[letterId]
    ) {
      return;
    }

    const views = getState().firestore.data.letters[letterId].views || 0;

    const fireStore = getFirestore();
    return fireStore
      .collection('letters')
      .doc(letterId)
      .update({ views: views + 1 })
      .then(() => {
        return dispatch({ type: "UPDATE_VIEW_COUNT" })
      })
      .catch((err) => {
        return dispatch({
          type: "UPDATE_VIEW_COUNT_ERROR"
        }, err)
      });
  };
}

export const updateActiveLetter = (letterId) => {
  return (dispatch)=>{
    return dispatch({ type: "UPDATE_ACTIVE_LETTER", 'activeLetterId':letterId })
  }
}