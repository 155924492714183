import React from 'react';
import PostPayment from './PostPaymentCard';

const Failed = () => {
  return (
    <div>
      <PostPayment success={false} />
    </div>
  );
};

export default Failed;