import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import NavBar from './components/layout/NavBar';
// import Home from './components/home/Home';
import BrowseLetter from './components/letters/BrowseLetter';
import LetterDetail from './components/letters/LetterDetail';
import CreateLetter from './components/letters/CreateLetter';
import EditLetter from './components/letters/EditLetter';
import Profile from './components/user/profile';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Checkout from './components/checkout/Checkout';
import Track from './components/track/track';
import PaymentSuccess from './components/checkout/payment/Success';
import PaymentFailed from './components/checkout/payment/Failed';

import UIkit from 'uikit/dist/js/uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route path="/browse" component={BrowseLetter} />
            <Route path="/profile" component={Profile} />
            <Route exact path="/new" component={CreateLetter} />
            <Route path="/letter/:id" component={LetterDetail} />
            <Route path="/edit/:id" component={EditLetter} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/checkout/:id" component={Checkout} />
            <Route path="/track/:id" component={Track} />
            <Route exact path="/payment/success" component={PaymentSuccess} />
            <Route exact path="/payment/failed" component={PaymentFailed} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
