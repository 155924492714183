import React, { useEffect, useState } from 'react';

import successImg from './img/success.svg';
import printImg from './img/print.svg';
import errorImg from './img/error.svg';

const Track = (props) => {
  const trackId = props.match.params.id;
  const [trackData,
    setTrackData] = useState({ loading: true });
  const [trackHTML,
    setTrackHTML] = useState({ img: successImg, text: { title: ' Submitted successfully', body: 'Letters are sent for processing on the Next Day.' } });

  var trackLink = 'https://payment.letterflix.com/track';

  useEffect(() => {
    if (process.env.NODE_ENV === 'development')
      trackLink = 'http://localhost:5000/track';

    fetch(`${trackLink}/${trackId}`)
      .then((response) => {
        if (response.status !== 200) {
          setTrackData({ loading: false, item: { tracking_id: false } });
          setTrackHTML({ img: errorImg, text: { title: ' Something Went Wrong', body: '' } })
          return;
        }

        // Updates the response
        response.json().then((data) => {
          if (data.attributes.status === 'sent') {
            console.log("status from if", data.attributes.status)
            setTrackHTML({ img: printImg, text: { title: 'Your letter is sent', body: 'It will reach your recipient anytime' } })
          } else {
            setTrackHTML({ img: printImg, text: { title: 'Processing at the SendCenter', body: 'Carefully placing your words on paper. We are excited to hit the road with another fresh envelope.' } })
          }
          
          setTrackData({ loading: false, ...data });
        });
      }
      )
      .catch((error) => {
        setTrackData({ loading: false, ...error.data, item: { tracking_id: false } });
        setTrackHTML({ img: errorImg, text: { title: ' Something Went Wrong', body: '' } })
      });

  }, []);

  return (
    <section>
      {trackData.loading ? (
        <h2 className="uk-text-center uk-margin-large-top">Loading</h2>
      ) : (
        <div>
          {trackData.attributes && trackData.attributes.tracking_number ? (
            // <iframe
            //   title={"track"}
            //   style={{ width: "100vw", height: "100vh" }}
            //   src={`https://m.trackingmore.com/en/${trackData.attributes.tracking_number}`}
            //   height="100vh"
            //   width="100vw"
            // />
            <div className="uk-text-center uk-margin-large-top uk-container">
              <img src={trackHTML.img} alt="Success" />
              <h2 className="uk-margin-remove-bottom">
                {trackHTML.text.title}
              </h2>
              <p className="text-20px uk-margin-small-top">
                {trackHTML.text.body}
              </p>
              <p className='text-20px uk-margin-remove-bottom'>
                Tracking Id: <span className='uk-text-bold'>{trackData.attributes.tracking_number}</span>
              </p>
              <p className='text-20px uk-margin-remove-top'>Please track on: <a href='https://www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx' target="_blank">India Post</a> </p>
            </div>
          ) : (
            <div className="uk-text-center uk-margin-large-top uk-container">
              <img src={trackHTML.img} alt="Success" />
              <h2 className="uk-margin-remove-bottom">
                {trackHTML.text.title}
              </h2>
              <p className="text-20px uk-margin-small-top">
                {trackHTML.text.body}
              </p>
              <p>
                {trackData.attributes && trackData.attributes.status === 'sent'?"Live Tracking is only Available for Letters sent to and within India.":""}
              </p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Track;