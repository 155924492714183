import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import Editor from './Editor';

import { updateActiveLetter } from '../../store/actions/letterActions'

const EditLetter = (props) => {
  const letterId = props.match.params.id;
  const { letter } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.updateActiveLetter(letterId);
  }, [
    letterId, letter
      ? letter.id
      : 0
  ]);

  return (
    <div>
      {letter?(<Editor to={letter.to} content={letter.body} created={true} letterId={props.id} />):'<div>Loading</div>'}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const letters = state.firestore.data.letters;
  const letter = letters
    ? letters[id]
    : null;
  return { letter: letter, uid: state.firebase.auth.uid }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveLetter: (letterId) => {
      dispatch(updateActiveLetter(letterId));
    }
  }
}

export default compose(connect(mapStateToProps , mapDispatchToProps), firestoreConnect(props => [
  {
    collection: 'letters', doc: props.match.params.id,
  },
]))(EditLetter);