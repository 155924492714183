
var detectedUser = localStorage.getItem('detected-user');

export const detectUser = () => dispatch => {

  if (detectedUser) {
    dispatch({
      type: 'USER_DETECTED',
      payload: {
        detectedUser:JSON.parse(detectedUser),
      },
    })
  } else {
    return fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(json => dispatch({
        type: 'USER_DETECTED',
        payload: {
          detectedUser: json,
        },
      }));
  }
}