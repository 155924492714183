export const getPrice = (currency) =>{
  switch (currency) {
    case 'INR':
      return {currency,amount:199.00}
    case 'USD':
      return {currency,amount:2.99}
    case 'GBP':
      return {currency,amount:2.99}
    case 'CHF':
      return {currency,amount:2.99}
    case 'EUR':
      return {currency,amount:2.99}
    default:
      return {currency:'USD',amount:2.99}
  }
}