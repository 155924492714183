export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({type: 'LOGIN_SUCCESS'})
      })
      .catch((err) => {
        dispatch({
          type: "LOGIN_ERROR"
        }, err)
      })
  }
}

export const signInWithGoogle = (credentials) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((data) => {
        const userData = data.additionalUserInfo.profile;

        if (data.additionalUserInfo.isNewUser) {
          return firestore
          .collection('users')
          .doc(data.user.uid)
          .set({
            displayName:userData.name,
            userAvatar:userData.picture,
            registeredOn:new Date()
          })
        }
        return;

      })
      .then(()=> {
        dispatch({type: 'LOGIN_SUCCESS'})
      })
      .catch((err) => {
        dispatch({
          type: "LOGIN_ERROR"
        }, err)
      })
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            displayName: newUser.displayName,
            avatarUrl:"",
            registeredOn:new Date()
          })
      })
      .then(()=> {
        dispatch({type: 'SIGNUP_SUCCESS'})
      })
      .catch((err) => {
        dispatch({
          type: "SIGNUP_ERROR"
        }, err)
      })
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {

    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({type: "SIGNOUT_SUCCESS"})
      })
  }
}