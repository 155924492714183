import React from 'react';
import {Link} from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import SignedOutLinks from './SignedOutLinks'
import {connect} from 'react-redux'

import './style.css';

const NavBar = (props) => {
  const {auth,profile} = props;
  return (
    <header className="navbar-wrapper">
      <div className="uk-container">
        <nav
          className={`uk-navbar-container uk-navbar-transparent`}
          uk-navbar="true"
        >
          <div className="uk-navbar-left">
            <Link to="/" className="uk-logo" href="#">
              letterflix.com
            </Link>
          </div>
          {auth.uid ? (
            <SignedInLinks userAvatar={profile.userAvatar} />
          ) : (
            <SignedOutLinks />
          )}
        </nav>
      </div>
    </header>
  );
};

const mapStateProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile:state.firebase.profile
  }
}

export default connect(mapStateProps)(NavBar);