import React from 'react';
import Editor from './Editor';

const CreateLetter = () => {
  return (
    <div>
      <Editor to="" content="" created={false} letterId={null} />
    </div>
  );
};

export default CreateLetter;