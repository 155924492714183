const initState = { activeLetterId: null };

const letterReducer = (state = initState, action) => {

  switch (action.type) {
    case 'CREATE_LETTER':
      return { ...state, activeLetterId: action.activeLetterId };

    case 'CREATE_LETTER_ERROR':
      return state;

    case 'UPDATE_VIEW_COUNT':
      return state;

    case 'UPDATE_VIEW_COUNT_ERROR':
      return state;

    case 'UPDATE_LETTER':
      return state;

    case 'UPDATE_LETTER_ERROR':
      return state;

    case 'UPDATE_ACTIVE_LETTER':
        return { ...state, activeLetterId: action.activeLetterId };

    default:
      return state;
  }
}

export default letterReducer;