const LOCALSTORAGE_USER_KEY = 'detected-user';

const initialState = { detectedUser: JSON.parse(localStorage.getItem(LOCALSTORAGE_USER_KEY)) };

export default (state = initialState, action) => {
  if (action.type === 'USER_DETECTED') {
    const { detectedUser } = action.payload;
    localStorage.setItem(LOCALSTORAGE_USER_KEY, JSON.stringify(detectedUser));
    return { ...state, detectedUser };
  }

  return state;
};
