import React from 'react';

const PostPaymentCard = (props) => {
  const {success, session_id} = props;
  return (
    <div className="post-payment uk-container uk-container-xsmall uk-margin-large-top uk-margin-auto-left uk-margin-auto-right">
      {
        success ?
          <div>
            {
              session_id ?
                <div className="uk-card uk-card-default uk-card-body uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right">
                  <h3 className="uk-card-title uk-text-success">Payment success</h3>
                  <h4 className="bold uk-margin-remove-bottom">Your Letter is on the way !</h4>
                </div>
                :
                <div className="uk-card uk-card-default uk-card-body uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right">
                  <h3 className="uk-card-title uk-text-danger">Something Went Wrong</h3>
                </div>
            }
          </div>
          :
          <div className="uk-card uk-card-default uk-card-body uk-width-2-3@m uk-margin-auto-left uk-margin-auto-right">
            <h3 className="uk-card-title uk-text-danger">Payment Failed</h3>
            <h4 className="bold uk-margin-remove-bottom">Retry Payment</h4>
          </div>
      }
    </div>
  );
};

export default PostPaymentCard;