import authReducer from './authReducers';
import letterReducer from './letterReducers';
import userDetectionReducer from './userDetectionReducer';
import {combineReducers} from 'redux';
import {firestoreReducer} from 'redux-firestore';
import {firebaseReducer} from 'react-redux-firebase';

const rootReducer = combineReducers({
  auth: authReducer,
  letter: letterReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  userDetection: userDetectionReducer,
});

export default rootReducer;
